import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';

function Whooprecovery() {
	const[recovery,setRecovery] = useState([]);
	const[summary, setSummary] = useState([]);
	
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
		
		useEffect(() => {
    		getRecoveryData(timeFrame);
			getSummaryData(timeFrame);	
        },[timeFrame])  
		
		const getRecoveryData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'whoop_recovery',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
			.then (res => res.json())
			.then (data=>setRecovery( data))
        };
        
        const getSummaryData = (timeFrame) => {
        	fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'whoop_recovery_summary',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setSummary( data ))
        };

    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Recovery</title>
			</Helmet>
       		<div id = "page-wrap">
       		<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		<div className="container">
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
        		
        		<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex align-items-center justify-content-center">
								<table className="table table-bordered table-striped table-sm">
									<thead className="thead-inverse">
									<tr>
										<td className="text-center" colSpan="8">
												<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
												<h4>Recovery Overview - Last {timeFrame} Days</h4>
											
										</td>
									</tr>	
									<tr>
										<th className="text-center">Avg Recovery Score</th>
										<th className="text-center">Avg Resting Heart Rate</th>
										<th className="text-center">Avg Heart Rate Variability</th>
										<th className="text-center">Avg O2 Percentage</th>
										<th className="text-center">Avg Skin Temp</th>
										<th className="text-center">Avg Strain</th>
										<th className="text-center">Avg Max Heart Rate</th>
										<th className="text-center">Avg Heart Rate</th>
									</tr>
									</thead>
									<tbody>	 	
									{summary.map((sdata) => (
										<tr>
											<td span style ={{color: sdata.changeColorRecovery, fontWeight: 'bold', backgroundColor: 'white'}} >
												{sdata.avg_recovery_score}
											</td>
											<td>{sdata.avg_resting_heartrate}</td>
											<td>{sdata.avg_hrv}</td>
											<td>{sdata.avg_o2_percentage}%</td>
											<td>{sdata.avg_skin_temp_fahrenheit}° F</td>
											<td>{sdata.avg_strain}</td>
											<td>{sdata.avg_max_heartrate}</td>
											<td>{sdata.avg_heartrate}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>	
						</div>	
        		
        			<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="9">
	  							<h4>Recovery in the Last {timeFrame} Days</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th className="text-center">Recovery Date</th>
    						<th className="text-center">Recovery Score</th>
    						<th className="text-center">Resting Heart Rate</th>
    						<th className="text-center">Heart Rate Variability</th>
    						<th className="text-center">O2 Percentage</th>
    						<th className="text-center">Skin Temp</th>
    						<th className="text-center">Total Strain</th>
    						<th className="text-center">Max Heartrate</th>
    						<th className="text-center">Avg Heartrate</th>
    					</tr>	
        				</thead>
        				<tbody>	 	
          				{recovery.map((recovery) => (
          				<tr>
          					<td>
								<Moment format="MM/DD/YYYY @ hh:mm a">{recovery.updated_at}</Moment>
          					</td>
							<td span style ={{color: recovery.changeColorRecovery, fontWeight: 'bold', backgroundColor: 'white'}} >
            					{recovery.recovery_score}
            				</td>
            				<td>{recovery.resting_heart_rate}</td>
            				<td span style ={{color: recovery.changeColorHrv, fontWeight: 'bold',backgroundColor: 'white'}} >
            					{recovery.hrv}
            				</td>
            				<td>{recovery.o2_percentage}%</td>
            				<td>{recovery.skin_temp_fahrenheit}° F</td>
            				<td span style ={{color: recovery.changeColorCycleStrain, fontWeight: 'bold',backgroundColor: 'white'}} >
            					{recovery.totalStrain}
            				</td>
            				<td>{recovery.maxHeartrate}</td>
            				<td>{recovery.avgHeartrate}</td>
        				</tr>
          				))}
          				</tbody>
          			</table>
          			<h5>Statistics in <span className="text-danger">red </span>indicates highest statistics in the past {timeFrame} days.</h5>
	       			<h5>Recovery Score &gt;= 67 in <span className="text-success fw-bold">green</span>, &lt;= 33 in <span className="text-danger fw-bold">red</span>, else in <span className="text-warning fw-bold">orange</span>.</h5>

        		</div>
       		</div>
       	</div>
    </div>
    );
}
export default Whooprecovery;



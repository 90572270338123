import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';

function Googlefit() {

const[googlefit, setGooglefit] = useState([]);
	
		//fetch('https://stats.firehole.us/api/whoop')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
		
		//remove beginning and ending quotes
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getGoogleFitData(timeFrame);
				
        },[timeFrame])  

        const getGoogleFitData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'google_fit',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setGooglefit( data ))
     	};          

    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Google Fit</title>
			</Helmet>
       		<div id = "page-wrap">
       		<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		<div className="container">
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
        			<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="11">
	  							<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
	  	 						<h4>Google Fit Stats in the Last {timeFrame} Days</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th className="text-center">Date</th>
    						<th className="text-center">Heart Points</th>
    						<th className="text-center">Steps</th>
    						<th className="text-center">Active Minutes</th>
    						<th className="text-center">Min HR</th>
    						<th className="text-center">Max HR</th>
    						<th className="text-center">Avg HR</th>
    						<th className="text-center">Min Resting HR</th>
    						<th className="text-center">Max Resting HR</th>
    						<th className="text-center">Avg Resting HR</th>
    						<th className="text-center">Calories Expended</th>
        				</tr>
        				</thead>
        				<tbody>	 	
          				{googlefit.map((googlefit) => (
          				<tr>
          					<td>
          						<Moment format="MM/DD/YYYY">{googlefit.date}</Moment>
            				</td>
            				<td span style ={{color: googlefit.changeColorHeartPoints, backgroundColor: 'white'}} >
            					{googlefit.heart_points}
            				</td>
            				<td span style ={{color: googlefit.changeColorSteps, backgroundColor: 'white'}} >
            					{googlefit.steps}
            				</td>
            				<td span style ={{color: googlefit.changeColorActiveMinutes, backgroundColor: 'white'}} >
            					{googlefit.active_minutes}
            				</td>
            				<td>{googlefit.min_heart_rate}</td>
            				<td>{googlefit.max_heart_rate}</td>
            				<td>{googlefit.avg_heart_rate}</td>
            				<td>{googlefit.min_resting_heart_rate}</td>
            				<td>{googlefit.max_resting_heart_rate}</td>
            				<td>{googlefit.avg_resting_heart_rate}</td>
            				<td span style ={{color: googlefit.changeColorCaloriesExpended, backgroundColor: 'white'}} >
            					{googlefit.calories_expended}
            				</td>
        				</tr>
          				))}
          				</tbody>
          			</table>
          	 		<h5>Statistic in <span className="text-danger">red </span>indicates highest Statistic in the past {timeFrame} days.</h5>
        		</div>
       		</div>
       	</div>
    </div>
	);
}

export default Googlefit;
import React from "react";

import "./styles/styles.css";

export const PageLoader = () => {
  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

  return (
    <div className="page-layout">
		
    	<div className="loader">
      		<img src={loadingImg} alt="Loading..." />
    	</div>
  	</div>
  );
};

import React, { useState }from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function TimeFrameDropDown2({setTimeFrame,selectOptions}) {

	const HandleSelect = (e) => {
		setTimeFrame(e);		
    }; 

	return (
		<DropdownButton 
		onSelect={HandleSelect}
		
		title="Timeframe"
		id="dropdown-basic-button"
		>
		{selectOptions.map((dropdownOptions) => (
			<Dropdown.Item eventKey = {dropdownOptions.key}>{dropdownOptions.value}</Dropdown.Item>
		))}
			
		</DropdownButton>
	);
}		
export default TimeFrameDropDown2;
import React, { Component } from 'react';
import "./styles/styles.css";

import {NavBarButtons} from "./navigation/buttons/nav_bar_buttons";
import NavigationBar from './navigation/NavigationBar';

class Header extends Component {

	render() {
		return (
				<div id="outer-container">
					<div className="navbar navbar-light bg-light py-0">
						<NavigationBar></NavigationBar> 
			{/*			<SideBar /> */}
						<NavBarButtons />
					</div>
				</div>
		);
	}
}

export default Header;
	
	
	
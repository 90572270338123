import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {Helmet} from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "../styles/styles.css";
import '../styles/index.css';
import "react-big-calendar/lib/css/react-big-calendar.css";


//<div className={`modal-${modalState == true ? 'show' : 'hide'}`}>

const localizer = momentLocalizer(moment);

function Activitycalendar() {
		const [calendar, setCalendar] = useState([]);
		const [selectedEvent, setSelectedEvent] = useState(undefined)
   		const [show, setShow] = useState(false);
   		const handleClose = () => setShow(false);
   		
   		const navigate = useNavigate(); 
   		    	
    	const App_Key = '!4uYm7D7Q2Yx';
    	var accessToken = "";
 	 
 	 	const handleSelectedEvent = (event) => {
      		setSelectedEvent(event)
      		setShow(true)
   		}
 	 
 	 	const ModalWindow = () => {

			return (
				<>
				<Modal show={show} onHide={handleClose} animation={false}>
					<Modal.Header closeButton>
						<Modal.Title>{selectedEvent.resource.activityName}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<small>
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Time - {selectedEvent.resource.startTime}</p>}
							
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Today's Weight - {selectedEvent.resource.weight}  - Trending
							<span style={{color: selectedEvent.resource.trendingWeightColor, fontWeight: 'bold'}}> {selectedEvent.resource.weightTrending}</span></p>}
							
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Avg Weight, last 90 days - {selectedEvent.resource.avgWeight}</p>}
							
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>BMI - {selectedEvent.resource.bmi} - Trending
							<span style={{color: selectedEvent.resource.trendingBmiColor, fontWeight: 'bold'}}> {selectedEvent.resource.bmiTrending}</span></p>}
	
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Avg BMI, last 90 days - {selectedEvent.resource.avgBmi}</p>}
							
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Fat Ratio - {selectedEvent.resource.fatRatio} - Trending
							<span style={{color: selectedEvent.resource.trendingFatRatioColor, fontWeight: 'bold'}}> {selectedEvent.resource.fatRatioTrending}</span></p>}
	
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Avg Fat Ratio, last 90 days - {selectedEvent.resource.avgFatRatio}</p>}
							
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Fat Mass - {selectedEvent.resource.fatMass}</p>}
							{selectedEvent.resource.isWeight && <p style = {{lineHeight: 0.5}}>Muscle Mass - {selectedEvent.resource.muscleMass}</p>}
							
							
							{selectedEvent.resource.isActivity && <p style = {{lineHeight: 0.5}}>Start Time - {selectedEvent.resource.startTime}</p>}
							{selectedEvent.resource.isActivity && <p style = {{lineHeight: 0.5}}>End Time - {selectedEvent.resource.endTime}</p>}
							{selectedEvent.resource.isActivity && <p style= {{lineHeight: 0.5}}>Elapsed Time - {selectedEvent.resource.elapsedTime} mins</p>}
							{selectedEvent.resource.isActivity && selectedEvent.resource.distance && <p style={{lineHeight: 0.6}}>Distance - {selectedEvent.resource.distance} miles</p>}
							{selectedEvent.resource.isActivity && <p style={{lineHeight: 0.5}}>Average Heartrate - {selectedEvent.resource.avgHr} BPM</p>}
							{selectedEvent.resource.isActivity && <p style={{lineHeight: 0.5}}>Maximum Heartrate - {selectedEvent.resource.maxHr} BPM</p>}
							{selectedEvent.resource.isActivity && selectedEvent.resource.strain && <p style={{lineHeight: 0.6}}>Whoop Strain - {selectedEvent.resource.strain}</p>}
							
							{selectedEvent.resource.isSleep && <p style = {{lineHeight: 0.5}}>Start Time - {selectedEvent.resource.startTime}</p>}
							{selectedEvent.resource.isSleep && <p style = {{lineHeight: 0.5}}>End Time - {selectedEvent.resource.endTime}</p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Time Awake - {selectedEvent.resource.totalAwakeTime}</p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Time Light Sleep - {selectedEvent.resource.totalLightSleepTime}</p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Time Deep Sleep - {selectedEvent.resource.totalDeepSleepTime}</p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Time REM Sleep - {selectedEvent.resource.totalRemSleepTime}</p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Recovery Score - <span style={{color: selectedEvent.resource.changeColorRecovery, fontWeight: 'bold'}}>{selectedEvent.resource.recoveryScore}</span></p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Resting Heartrate - {selectedEvent.resource.restingHr}</p>}
							{selectedEvent.resource.isSleep && <p style={{lineHeight: 0.5}}>Heartrate Variability - {selectedEvent.resource.hrv}</p>}
						</small>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClose}>
							Close
						</Button>
					</Modal.Footer>
      			</Modal>
    			</>
 			 );			  				
		}
 	 
 	 	accessToken = JSON.parse(localStorage['token']);
		
    	useEffect(() => {
				fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'activity_calendar',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setCalendar( data ))
        },[])
        for (let i = 0; i < calendar.length; i++ ) {
        	calendar[i].start = moment(calendar[i].start).toDate();
        	calendar[i].end = moment(calendar[i].end).toDate();
        }
		
		return(
	
			<div id = "outer-container">
				<div id = "page-wrap">
					<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
						<div className="container-fluid">
						<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
							<Helmet>
								<title>Firehole - Activity Calendar</title>
							</Helmet>
							{selectedEvent && <ModalWindow />}
							<Calendar 
								eventPropGetter={(calendar) => {
 									const backgroundColor = calendar.resource.backgroundColor;
 									
  									return { style: { backgroundColor } }
								}}
								localizer={localizer} startAccessor="start" endAccessor="end" showAllEvents="true" selectable
								views={{week:true, day: true, month: true}} events={calendar} style={{ height: '80vh'}} 
								onSelectEvent={(e) => handleSelectedEvent(e)}
							/>
						</div>
					</div>	
				</div>
			</div>
	
		);
	}

export default Activitycalendar;
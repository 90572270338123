import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import Stack from '@mui/material/Stack';


function Peloton() {

		const[peloton_num, setPeloton_num] = useState([]);
		const[peloton_workouts, setPeloton_workouts] = useState([]);
		const[peloton_records, setPeloton_records] = useState([]);
		const[peloton_details, setPeloton_details] = useState([]);
		const[peloton_streaks, setPeloton_streaks] = useState([]);

	
		//fetch('https://services.firehole.us/api/whoop')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
		
		//remove beginning and ending quotes
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'peloton_num_workouts',
				'WORKOUTID': '',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setPeloton_num( data ))
     	},[])
     	
     	useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'peloton_workouts',
				'WORKOUTID': '',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setPeloton_workouts( data ))

     	},[])     
     	
     	useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'peloton_records',
				'WORKOUTID': '',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setPeloton_records( data ))

     	},[])    
     	
     	const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getPelotonDetailsData(timeFrame);
        },[timeFrame])  
        
        const getPelotonDetailsData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'peloton_details',
				'TIMEFRAME':`${timeFrame}`,
				'WORKOUTID': '',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setPeloton_details( data ))

     	};
     	 
     	useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'peloton_streaks',
				'WORKOUTID': '',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setPeloton_streaks( data ))

     	},[])    

	const totalWorkouts = peloton_num.map((peloton_num) => <span>{peloton_num.total_workouts}</span>);
	const workoutName = peloton_num.map((peloton_num) => <span>{peloton_num.workout_name}</span>);
	const firstWorkoutDate = peloton_num.map((peloton_num) => <span>{peloton_num.first_workout_date}</span>);
	
	const currentStreak = peloton_streaks.map((peloton_streaks) => <span>{peloton_streaks.current_weekly}</span>);
	const bestStreak = peloton_streaks.map((peloton_streaks) => <span>{peloton_streaks.best_weekly}</span>);
	const currentStreakStartDate = peloton_streaks.map((peloton_streaks) => <span>{peloton_streaks.start_date_of_current_weekly}</span>);
	
    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Peloton</title>
			</Helmet>
       		<div id = "page-wrap">
				<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
					<Stack direction = 'row'>
						<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex align-items-center justify-content-center">
								<table className="table table-bordered table-striped table-sm">
									<thead className="thead-inverse">
									<tr>
										<td className="text-center" colSpan="2">
											
												<h4>Peloton Overview - {totalWorkouts} total workouts, 1st workout date - {firstWorkoutDate}</h4>
											
										</td>
									</tr>	
									<tr>
										<th className="text-center">Workout Name</th>
										<th className="text-center">Workout Count</th>
									</tr>
									</thead>
									<tbody>	 	
									{peloton_workouts.map((pworkouts) => (
										<tr>
											<td>{pworkouts.name}</td>
											<td>{pworkouts.count}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>	
						</div>	
					
						<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex">
								<table className="table table-bordered table-striped table-sm">
									<thead className="thead-inverse">
									<tr>
										<td className="text-center" colSpan="3">
											
												<h4>Peloton Cycling Records</h4>
											
										</td>
									</tr>	
									<tr>
										<th className="text-center">Workout Length</th>		
										<th className="text-center">Date</th>
										<th className="text-center">Total Output</th>
			
									</tr>
									</thead>
									<tbody>
									{peloton_records.map((precords) => (
										<tr>
											<td><Link to={"/pelotonspecificone?workoutid="+precords.workout_id} activeClassName="active">{precords.name}</Link></td>
											<td>{precords.workout_date}</td>
											<td>{precords.value}</td>
										</tr>
									))}
									</tbody>	 
								</table>
							</div>		
						</div>	
					</Stack>
					<div className="container-fluid">
						<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
							<table className="table table-bordered table-striped w-75 table-sm align-self-center align-items-center text-center">
								<thead className="thead-inverse">
								<tr>
									<td className="text-center" colSpan="7">
										<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
										<h4>Peloton Activities, last {timeFrame} days</h4>
										<h5>Current Streak - {currentStreak} weeks, Best Streak - {bestStreak} weeks, Current Streak Start Date - {currentStreakStartDate}</h5>
									</td>
								</tr>	
								<tr>
									<th className="text-center">Workout Date</th>
									<th className="text-center">Workout Time</th>			
									<th className="text-center">Workout Name</th>
									<th className="text-center">Instructor Name</th>
									<th className="text-center">Leaderboard Rank</th>
									<th className="text-center">Averages</th>
									<th className="text-center">Maximums</th>

		
								</tr>
								</thead>
								<tbody>
								{peloton_details.map((drecords) => (
									<tr>
										<td>{drecords.start_date}</td>
										<td><Link to={"/pelotonspecificone?workoutid="+drecords.workout_id} activeClassName="active">{drecords.workout_time}</Link></td>
										<td>{drecords.workout_name}</td>
										<td>{drecords.instructor_name}</td>
										<td>{drecords.leaderboard_rank}</td>
										<td>
											<Stack>
												<div className="d-flex justify-content-start">{drecords.avg_performance_label0}</div>
												<div className="d-flex justify-content-start">{drecords.avg_performance_label1}</div>
												<div className="d-flex justify-content-start">{drecords.avg_performance_label2}</div>
												<div className="d-flex justify-content-start">{drecords.avg_performance_label3}</div>
												<div className="d-flex justify-content-start">{drecords.avg_performance_label4}</div>

											</Stack>
										</td>
										<td>
											<Stack>
												<div className="d-flex justify-content-start">{drecords.max_performance_label0}</div>
												<div className="d-flex justify-content-start">{drecords.max_performance_label1}</div>
												<div className="d-flex justify-content-start">{drecords.max_performance_label2}</div>
												<div className="d-flex justify-content-start">{drecords.max_performance_label3}</div>
												<div className="d-flex justify-content-start">{drecords.max_performance_label4}</div>
											</Stack>
										</td>
									</tr>
								))}
								</tbody>	 
							</table>
						</div>		
					</div>	
					
				</div>
       		</div>

	);
}

export default Peloton;
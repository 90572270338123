import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Stack from '@mui/material/Grid';
import Grid from '@mui/material/Grid';

import Button from 'react-bootstrap/Button';

import Moment from 'react-moment';

import { LineChart, Line, CartesianGrid, XAxis, YAxis,Tooltip, ReferenceLine } from 'recharts';

import "../styles/styles.css";


function Smashrunspecificone() {

		
		const[smashrun_specificone, setSmashrun_specificone] = useState([]);

		const search = useLocation().search.slice(1);
		const { runid } = Object.fromEntries(new URLSearchParams(search));
	
		//fetch('https://services.firehole.us/api/whoop')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		//remove beginning and ending quotes
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'smashrun_specificone',
				'RUNID': `${runid}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setSmashrun_specificone( data ))
     	},[])
     	
	const navigate = useNavigate();
	const graph_width = 1500;
	const graph_height = 500;

    return (
    	
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Runs</title>
			</Helmet>
       		<div id = "page-wrap">
				<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
					
					<div className="container-fluid">
						<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
							<table className="table table-bordered table-striped w-75 table-sm align-self-center align-items-center text-center">
								<thead className="thead-inverse">
								<tr>
									<td className="text-center" colSpan="10">
										
											<h4>Run Details</h4>
										
									</td>
								</tr>	
								<tr>
									<th className="text-center">Run Date/Time</th>
									<th className="text-center">Distance</th>			
									<th className="text-center">Duration</th>			
									<th className="text-center">Pace</th>
									<th className="text-center">Fastest Mile Pace</th>
									<th className="text-center">HR - Min / Avg / Max</th>
									<th className="text-center">Cadence - Min / Avg / Max</th>
									<th className="text-center">Calories</th>	
									<th className="text-center">Weather</th>	
									<th className="text-center">Location</th>
								</tr>
								</thead>
								<tbody>
									{smashrun_specificone.map((srecord) => (
									<tr>
										<td>
											<Moment format="MM/DD/YYYY @ hh:mm a">{srecord.print_date}</Moment>
										</td>
										<td>{srecord.distance}</td>
										<td>{srecord.print_duration}</td>
										<td>{srecord.pace}</td>
										<td>{srecord.fastest_mile_pace}</td>
										<td>{srecord.hr_min} / {srecord.hr_avg} / {srecord.hr_max}</td>
										<td>{srecord.cadence_min} / {srecord.cadence_avg} / {srecord.cadence_max}</td>
										<td>{srecord.calories}</td>
										<td>
											<Stack>
												<div className="d-flex justify-content-start">Conditions: {srecord.weathcond}</div>
												<div className="d-flex justify-content-start">Temp: {srecord.temp}</div>
												<div className="d-flex justify-content-start">Windspeed: {srecord.windspeed}</div>
												<div className="d-flex justify-content-start">Wind Direction: {srecord.winddir}</div>

											</Stack>
										</td>
										<td>{srecord.location}</td>
									</tr>
								))}
								</tbody>
							</table>
						</div>		
						
						<div className="container-fluid" width="100%">
							{smashrun_specificone.map((srecord) => (					
							<div>	
								<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
									<LineChart width={graph_width} height={graph_height} data={srecord.cadence_array} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="10 10" />
										<XAxis dataKey="name" label={{value:"Minutes Elapsed",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:'RPM',position: 'insideLeft'}}/>
										<Tooltip />
										
										<ReferenceLine y={srecord.cadence_avg} stroke="red"/>
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Cadence</tspan>
										</text>
									</LineChart>
								</div>
								<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
									<LineChart width={graph_width} height={graph_height} data={srecord.speed_array} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="10 10" />
										<XAxis dataKey="name" label={{value:"Minutes Elapsed",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"MPH",position: 'insideLeft'}}/>
										<Tooltip />
										<ReferenceLine y={srecord.speed_avg} stroke="red"/>
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Speed</tspan>
										</text>
									</LineChart>
								</div>	
								<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
									<LineChart width={graph_width} height={graph_height} data={srecord.heartrate_array} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="10 10" />
										<XAxis dataKey="name" label={{value:"Minutes Elapsed",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"BPM",position: 'insideLeft'}}/>
										<Tooltip />
										<ReferenceLine y={srecord.hr_avg} stroke="red"/>
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Heart Rate</tspan>
										</text>
									</LineChart>
								</div>											
							</div>
							))}	
									
						</div>
					
						<Grid item xs={12} sm={12} md={4} lg={4}
   							style={{
       							textAlign:'center' // this does the magic
   							}}
							>
						<Button variant="primary" onClick={() => navigate(-1)}>Back</Button>
						</Grid>
						
					</div>	
					
				</div>
       		</div>

	);
}

export default Smashrunspecificone;
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import "./styles/styles.css";
import './styles/index.css';

const root = createRoot(document.getElementById('root'));

root.render(

<Auth0Provider
    		domain="firehole.auth0.com"
    		clientId="1wVP92vwoV3kXk4KnoL2JqGIucYeYB7E"
    		authorizationParams={{
    		redirect_uri: window.location.href,
    		//audience and scope are for getting token for API auth
    		audience: "https://services.firehole.us/api/whoop_sleep_api/",
    		scope: "read:sleep"
    		}}
  			>
  			<App />
</Auth0Provider>
    
);

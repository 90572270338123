import React, { useEffect,useState } from 'react';

import 'moment-timezone';

function Weeklymonthly() {

const[runs, setRuns] = useState([]);

	//fetch('https://stats.firehole.us/api/smashruntolocal')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		accessToken = JSON.parse(localStorage['token']);
		
		useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'smashrun_weeklymonthly',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setRuns( data ))
     	},[])        

    return (	
    	<div id="outer-container">
       		
       		<div id = "page-wrap">
       			<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
    			<div className="container">
    				<div className="col-lg-12 col-xs-12 col-md-8">
						<table className="table table-bordered table-striped table-sm">
						<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="3">
	  							<h4>Weekly, Monthly, This Day in History</h4>
	  						</td>
	  					</tr>
						<tr>
							<th>Stat</th>
							<th>Distance</th>
							<th>Avg Pace</th>
						</tr>
						</thead>
						<tbody>
							{runs.map((runs) => (
          					<tr>
								<td className="text-left">{runs.label}</td>
								<td>{runs.distance}</td>
								<td>{runs.print_pace}</td>
							</tr>
							))}
						</tbody>
						</table>
					</div>
				</div>
			</div>	
    	</div>
    );
}

export default Weeklymonthly;



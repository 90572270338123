import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';

function Whoopsleep() {

	const[sleep, setSleep] = useState([]);
	const[summary, setSummary] = useState([]);		
	
		//fetch('https://services.firehole.us/api/whoop')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
		
		//remove beginning and ending quotes
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getSleepData(timeFrame);
    		getSummaryData(timeFrame);
        },[timeFrame])  
		
        const getSleepData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'whoop_sleep',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setSleep( data ))
     	};          
     	const getSummaryData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'whoop_sleep_summary',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setSummary( data ))
     	};         

    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Sleep</title>
			</Helmet>
       		<div id = "page-wrap">
       		<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		
       		<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex align-items-center justify-content-center">
								<table className="table table-bordered table-striped table-sm">
									<thead className="thead-inverse">
									<tr>
										<td className="text-center" colSpan="12">
												<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
												<h4>Sleep Overview - Last {timeFrame} Days</h4>
											
										</td>
									</tr>	
									<tr>
										<th className="text-center">Avg Time in Bed</th>
										<th className="text-center">Avg Awake Time</th>
										<th className="text-center">Avg Light Sleep Time</th>
										<th className="text-center">Avg Deep Sleep Time</th>
										<th className="text-center">Avg REM Sleep Time</th>
										<th className="text-center">Avg Sleep Dept</th>
										<th className="text-center">Avg Cycle Count</th>
										<th className="text-center">Avg Disturbance Count</th>
										<th className="text-center">Avg Respiratory Rate</th>
										<th className="text-center">Avg Performance %</th>
										<th className="text-center">Avg Consistency %</th>
										<th className="text-center">Avg Efficiency %</th>
									</tr>
									</thead>
									<tbody>	 	
									{summary.map((sdata) => (
										<tr>
											<td>{sdata.avg_total_in_bed_hours}hrs {sdata.avg_total_in_bed_minutes}mins</td>
											<td>{sdata.avg_total_awake_hours}hrs {sdata.avg_total_awake_minutes}mins</td>
											<td>{sdata.avg_total_light_sleep_hours}hrs {sdata.avg_total_light_sleep_minutes}mins</td>
											<td>{sdata.avg_total_deep_sleep_hours}hrs {sdata.avg_total_deep_sleep_minutes}mins</td>
											<td>{sdata.avg_total_rem_sleep_hours}hrs {sdata.avg_total_rem_sleep_minutes}mins</td>
											<td>{sdata.avg_total_debt_sleep_hours}hrs {sdata.avg_total_debt_sleep_minutes}mins</td>
											<td>{sdata.avg_sleep_cycle_count}</td>
											<td>{sdata.avg_sleep_disturbance_count}</td>
											<td>{sdata.avg_respiratory_rate}</td>
											<td>{sdata.avg_sleep_performance_percentage}%</td>
											<td>{sdata.avg_sleep_consistency_percentage}%</td>
											<td>{sdata.avg_sleep_efficiency_percentage}%</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>	
						</div>	
       		
       		<div className="container-fluid">
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
        			<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="13">
	  							<h4>Sleep in the Last {timeFrame} Days</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th className="text-center">Sleep Date</th>
    						<th className="text-center">Time in Bed</th>
    						<th className="text-center">Awake Time</th>
    						<th className="text-center">Light Sleep Time</th>
    						<th className="text-center">Deep Sleep Time</th>
    						<th className="text-center">REM Sleep Time</th>
    						<th className="text-center">Sleep Debt</th>
    						<th className="text-center">Cycle Count</th>
    						<th className="text-center">Disturbance Count</th>
    						<th className="text-center">Respiratory Rate</th>
    						<th className="text-center">Performance %</th>
    						<th className="text-center">Consistency %</th>
    						<th className="text-center">Efficiency %</th>
        				</tr>
        				</thead>
        				<tbody>	 	
          				{sleep.map((sleep) => (
          				<tr>
          					<td>
          						<Moment format="MM/DD/YYYY @ hh:mm a">{sleep.start}</Moment>
          						{sleep.its_a_nap ? ' (nap)' : ''}
            				</td>
            				<td>{sleep.total_in_bed_hours}hrs {sleep.total_in_bed_minutes}mins</td>
            				<td>{sleep.total_awake_hours}hrs {sleep.total_awake_minutes}mins</td>
            				<td>{sleep.total_light_sleep_hours}hrs {sleep.total_light_sleep_minutes}mins</td>
            				<td>{sleep.total_deep_sleep_hours}hrs {sleep.total_deep_sleep_minutes}mins</td>
            				<td>{sleep.total_rem_sleep_hours}hrs {sleep.total_rem_sleep_minutes}mins</td>
            				<td>{sleep.total_debt_sleep_hours}hrs {sleep.total_debt_sleep_minutes}mins</td>
            				<td>{sleep.sleep_cycle_count}</td>
            				<td>{sleep.disturbance_count}</td>
            				<td>{sleep.respiratory_rate}</td>
            				<td>{sleep.sleep_performance_percentage}%</td>
            				<td>{sleep.sleep_consistency_percentage}%</td>
            				<td>{sleep.sleep_efficiency_percentage}%</td>
        				</tr>
          				))}
          				</tbody>
          			</table>
        		</div>
       		</div>
       	</div>
    </div>
	);
}

export default Whoopsleep;
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';

function Withings() {

const[withings, setWithings] = useState([]);
const[summary, setSummary] = useState([]);	
  		
	
		//fetch('https://services.firehole.us/api/whoop')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		const selectOptions = [
    		{	key: '30',
    			value: '30 readings',
    		},	
    		{	key: '60',
    			value: '60 readings',
    		},
    		{	key: '90',
    			value: '90 readings',
    		},
    		{	key: '120',
    			value: '120 readings',
    		},
    		{	key: '150',
    			value: '150 readings',
    		},
    		{	key: '180',
    			value: '180 readings',
    		},
    	]
		
		//remove beginning and ending quotes
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getWithingsData(timeFrame);
			getSummaryData(timeFrame);	
        },[timeFrame])  
		
        const getWithingsData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'withings',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setWithings( data ))
     	};
     	const getSummaryData = (timeFrame) => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'withings_summary',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setSummary( data ))
     	};               
    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Withings</title>
			</Helmet>
       		<div id = "page-wrap">
       		
       		<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		<div className="container">
       		
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
        			<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex align-items-center justify-content-center">
        			<table className="table table-bordered table-striped table-sm">
						<thead className="thead-inverse">
						<tr>
							<td className="text-center" colSpan="7">
									<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
									<h4>Withings Overview - Last {timeFrame} Readings</h4>
								
							</td>
						</tr>	
						<tr>
							<th className="text-center">Avg Weight</th>
							<th className="text-center">Avg Fat Mass</th>
							<th className="text-center">Avg Fat Free Mass</th>
							<th className="text-center">Avg Fat Ratio</th>
							<th className="text-center">Avg Bone Mass</th>
							<th className="text-center">Avg Muscle Mass</th>
							<th className="text-center">Avg BMI</th>
						</tr>
						</thead>
						<tbody>	 	
							{summary.map((sdata) => (
								<tr>
									<td>{sdata.avg_weight}</td>
									<td>{sdata.avg_fat_mass}</td>
									<td>{sdata.avg_fat_free_mass}</td>
									<td>{sdata.avg_fat_ratio}</td>
									<td>{sdata.avg_bone_mass}</td>
									<td>{sdata.avg_muscle_mass}</td>
									<td>{sdata.avg_bmi}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>	
			</div>	
        	<table className="table table-bordered table-striped table-sm">

	  			<thead className="thead-inverse">
	  			<tr>
	  				<td className="text-center" colSpan="8">
	  					<h4>Withings Data - Last {timeFrame} Readings</h4>
	  				</td>
	  			</tr>	
	  			<tr>
					<th className="text-center">Date</th>
					<th className="text-center">Weight</th>
					<th className="text-center">Fat Mass</th>
					<th className="text-center">Fat Free Mass</th>
					<th className="text-center">Fat Ratio</th>
					<th className="text-center">Bone Mass</th>
					<th className="text-center">Muscle Mass</th>
					<th className="text-center">BMI</th>
				</tr>
        		</thead>
        		<tbody>	 	
				{withings.map((withings) => (
				<tr>
					<td>
						<Moment format="MM/DD/YYYY @ hh:mm a">{withings.readable_occured_timestamp}</Moment>
					</td>
					<td span style ={{color: withings.changeColorWeight, fontWeight: 'bold',backgroundColor: 'white'}} >
						{withings.weight} lbs
					</td>
					<td span style ={{color: withings.changeColorFatMass, fontWeight: 'bold',backgroundColor: 'white'}} >
						{withings.fat_mass} lbs
					</td>
					<td>{withings.fat_free_mass} lbs</td>
					<td span style ={{color: withings.changeColorFatRatio, fontWeight: 'bold',backgroundColor: 'white'}} >
						{withings.fat_ratio} %
					</td>
					<td>{withings.bone_mass} lbs</td>
					<td span style ={{color: withings.changeColorMuscleMass, fontWeight: 'bold',backgroundColor: 'white'}} >
						{withings.muscle_mass} lbs
					</td>
					<td span style ={{color: withings.changeColorBmi, fontWeight: 'bold',backgroundColor: 'white'}} >
						{withings.bmi}
					</td>
				</tr>
				))}
				</tbody>
          	</table>
			<h5>Statistics in <span className="text-danger">red </span>indicates worst, in <span className="text-success">green </span> 
			best, in the last {timeFrame} readings.</h5>
        	</div>
       		</div>
       	</div>
    </div>
	);
}

export default Withings;
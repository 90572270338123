import React, { useEffect,useState } from 'react';
import Longestroutes from './longestroutes';
import Farthestroutes from './farthestroutes';


function Favoriteroutes() {

	const[runs, setRuns] = useState([]);


	//fetch('https://stats.firehole.us/api/smashruntolocal')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		accessToken = JSON.parse(localStorage['token']);
		
		useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'smashrun_favoriteroutes',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setRuns( data ))
     	},[])        

    return (
    <div>
   
    <div className="container">

   	        <div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
			<table className="table table-bordered table-striped table-sm">
				<thead className="thead-inverse">
				<tr>
					<td className="text-center" colSpan="3">
	        			<div id="logo" className="sitelogo"><img src="https://newstats.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>

	  					<h4>Favorite Routes</h4>
	  				</td>
	  			</tr>	
				<th>Location</th>
				<th>Count</th>
				<th>Route Distance</th>
				</thead>
				<tbody>
					{runs.map((runs) => (
						<tr>
 			           		<td className="text-left">{runs.location}</td>
 			           		<td className="text-center">{runs.count}</td>
 			           		<td className="text-center">{runs.route_distance}</td>
        				</tr>
          			))}
				</tbody>
			</table>
		</div>
<div>
	<Longestroutes />
	<Farthestroutes/>
</div>
	</div>	
	</div>
	);
}

export default Favoriteroutes;


